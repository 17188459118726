import React from 'react';
import { CmsData } from '~/models/cms-data';
import { isDefined } from '~/helpers';
import PageElements from '~/page-elements';

import styles from './grid.module.scss';

export type GridProps = {
    grid: CmsData.IGrid;
    widgetId?: string;
    pageId?: string;
};
export function Grid({ grid, pageId }: GridProps) {
    const content = grid?.sections?.[0]?.rows?.filter(isDefined).map((row) => (
        <div className={styles.grid} key={row.id}>
            {row.areas?.filter(isDefined).map((area, areaIndex) => (
                <div key={`${areaIndex}-${area.grid}-${row.id}`} className={styles.col} style={{ gridColumn: `span ${area.grid}` }}>
                    <PageElements pageId={pageId as string} pageElements={area.controls} columns={+area.grid} />
                </div>
            ))}
        </div>
    ));

    if (!content) {
        return null;
    }

    return <>{content}</>;
}
