import React from 'react';

import styles from './grid-container.module.scss';

export type GridContainerProps = {
    children: React.ReactNode | React.ReactNode[];
};

export function GridContainer({ children }: GridContainerProps) {
    return <div className={styles.content}>{children}</div>;
}
