import React, { FC } from 'react';
import dynamic from 'next/dynamic';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

import { Umbraco } from '~/models/umbraco.d';
import { CmsData } from '~/models/cms-data.d';
import ErrorBox from '~/shared/error-box/error-box';
import Button from '~/shared/buttons/button/button.component';
import { Grid } from '~/shared/grid';

interface IMappedElement extends Pick<CmsData.IPageElement, 'value'> {
    alias: CmsData.IPageElement['editor']['alias'];
}

interface IPageElementsProps {
    pageElements: CmsData.IPageElement[];
    pageId: string;
    columns?: number;
}

type IModuleList = {
    [key: string]: any;
};

const moduleList: IModuleList = {
    [Umbraco.WidgetTypes.unknownWidget]: dynamic(() => import('./unknown/unknown.component')),
    [Umbraco.WidgetTypes.basketWidget]: dynamic(() => import('./basket/basket.component')),
    [Umbraco.WidgetTypes.vehicleListWidget]: dynamic(() => import('./vehicle-models/vehicle-models.component')),
    [Umbraco.WidgetTypes.vehicleTypesListWidget]: dynamic(() => import('./vehicle-types/vehicle-types.component')),
    [Umbraco.WidgetTypes.oeSparePartsRequestWidget]: dynamic(() => import('./oe-spare-parts/oe-spare-parts.component')),

    // Products list
    [Umbraco.WidgetTypes.sparePartProductListWidget]: dynamic(() => import('./product-list-page/product-list-page.component')),
    [Umbraco.WidgetTypes.universalPartProductListWidget]: dynamic(() => import('./product-list-page/product-list-page.component')),
    [Umbraco.WidgetTypes.searchResultWidget]: dynamic(() => import('./product-list-page/product-list-page.component')),

    // Product Page
    [Umbraco.WidgetTypes.universalPartVariantWidget]: dynamic(() => import('./product-detail-card/product-detail-card.component')),
    [Umbraco.WidgetTypes.sparePartVariantWidget]: dynamic(() => import('./product-detail-card/product-detail-card.component')),

    // Category list
    [Umbraco.WidgetTypes.sparePartCategoryListWidget]: dynamic(() => import('./category-list/sparepart-category-list.component')),
    [Umbraco.WidgetTypes.universalPartCategoryListWidget]: dynamic(() => import('./category-list/universal-category-list.component')),

    // Overview
    [Umbraco.WidgetTypes.orderOverviewWidget]: dynamic(() => import('./overview/orders/orders.component')),
    [Umbraco.WidgetTypes.savedBasketsOverviewWidget]: dynamic(() => import('./overview/saved-baskets/saved-baskets.component')),
    [Umbraco.WidgetTypes.invoicesOverviewWidget]: dynamic(() => import('./overview/invoices/invoices.component')),
    [Umbraco.WidgetTypes.invoiceDetailOverviewWidget]: dynamic(() => import('./overview/invoices/invoice-details-page.component')),
    [Umbraco.WidgetTypes.savedOrdersOverviewWidget]: dynamic(() => import('./overview/saved-orders/saved-orders.component')),
    [Umbraco.WidgetTypes.creditsOverviewWidget]: dynamic(() => import('./overview/credits/credits.component')),
    [Umbraco.WidgetTypes.creditsDetailOverviewWidget]: dynamic(() => import('./overview/credits/credit-details-page.componant')),
    [Umbraco.WidgetTypes.returnsOverviewWidget]: dynamic(() => import('./overview/returns/returns.component')),
    [Umbraco.WidgetTypes.returnsDetailOverviewWidget]: dynamic(() => import('./overview/return-details/return-details.component')),
    [Umbraco.WidgetTypes.chemicalProductsWidget]: dynamic(() => import('./overview/chemical-products/chemical-products.component')),

    // MyFtz
    [Umbraco.WidgetTypes.manualWebordersOverviewWidget]: dynamic(() => import('./my-ftz/manual-orders/manual-orders.component')),
    [Umbraco.WidgetTypes.userAdministrationWidget]: dynamic(() => import('./users-administration/users-administration.component')),
    [Umbraco.WidgetTypes.impersonateWidget]: dynamic(() => import('./find-customer-page/find-customer-page.component')),
    [Umbraco.WidgetTypes.nFLoginWidget]: dynamic(() => import('./external-login/nf.component')),
    [Umbraco.WidgetTypes.haynesLoginWidget]: dynamic(() => import('./external-login/haynes-pro.component')),
    [Umbraco.WidgetTypes.favoritesWidget]: dynamic(() => import('./favorites/favorites.component')),
    [Umbraco.WidgetTypes.salesPriceMarkupWidget]: dynamic(() => import('./sales-price-markup/sales-price-markup.component')),

    // Content page elements
    [Umbraco.WidgetTypes.sectionHeading]: dynamic(() => import('./section-heading/section-heading.component')),
    [Umbraco.WidgetTypes.contentPageRichText]: dynamic(() => import('./raw-html/raw-html.component')), // ??
    [Umbraco.WidgetTypes.contentPageImage]: dynamic(() => import('./image-page-element/image-page-element.component')),
    [Umbraco.WidgetTypes.contentPageLinkContainer]: dynamic(() => import('./content-page/link-container/link-container.component')),
    [Umbraco.WidgetTypes.contentPageSmallAuthor]: dynamic(() => import('./content-page/employee-card-small/employee-card-small.component')),
    [Umbraco.WidgetTypes.contentPageDataTable]: dynamic(() => import('./data-table/data-table.component')),
    [Umbraco.WidgetTypes.contentPageEmployeesList]: dynamic(() => import('./content-page/employees-list-grid/employees-list-grid.component')),
    [Umbraco.WidgetTypes.contentPageSmallRichText]: dynamic(() => import('./content-page/small-rich-text/small-rich-text.component')),
    [Umbraco.WidgetTypes.contentPageLoginWidget]: dynamic(() => import('./content-page/login-hero/login-hero.component')),
    [Umbraco.WidgetTypes.ticketButlerScriptWidget]: dynamic(() => import('./content-page/script-content/ticket-butler-script-widget')),
    [Umbraco.WidgetTypes.textAndMedia]: dynamic(() => import('./text-media/text-media.component')),
    [Umbraco.WidgetTypes.titleIconRichText]: dynamic(() => import('./title-icon-richtext/title-icon-richtext.component')),
    [Umbraco.PageElementTypes.rte]: dynamic(() => import('./raw-html/raw-html.component')),
    [Umbraco.PageElementTypes.media]: dynamic(() => import('./media/media.component')),
    [Umbraco.WidgetTypes.chassisSupportRequestWidget]: dynamic(() => import('./car-chassis-support/car-chassis-support.component')),
    [Umbraco.WidgetTypes.replacementEngineRequestWidget]: dynamic(() => import('./car-replace-engine/car-replace-engine.component')),
    [Umbraco.WidgetTypes.vantageDashboardWidget]: dynamic(() => import('./vantage-dashboard/vantage-dashboard.component')),
    [Umbraco.WidgetTypes.labelOrderWidget]: dynamic(() => import('./labels/label-order.component')),
    [Umbraco.WidgetTypes.internalDashboardReturnDepositWidget]: dynamic(() => import('./internal-dashboard/return-deposit/deposit-button.component')),

    // Marketing
    [Umbraco.WidgetTypes.campaignSpot]: dynamic(() => import('./campaign-spot/campaign-spot.component')),
    [Umbraco.WidgetTypes.magazineSpot]: dynamic(() => import('./magazine-spot/magazine-spot.component')),
    [Umbraco.WidgetTypes.campaignSliderSpot]: dynamic(() => import('./campaign-slider-spot/campaign-slider-spot.component')),
    [Umbraco.WidgetTypes.raptorCampaignSliderSpot]: dynamic(() => import('./campaign-slider-spot/campaign-slider-raptor.component')),
    [Umbraco.WidgetTypes.ipaper]: dynamic(() => import('./ipaper/ipaper.component')),

    // Internal dashboard
    [Umbraco.WidgetTypes.internalUserInfoFrontpageWidget]: dynamic(() => import('./internal-dashboard/user-info/user-info.component')),
    [Umbraco.WidgetTypes.internalDashboardCustomerSearchWidget]: dynamic(() => import('./internal-dashboard/find-customer/find-customer.component')),
    [Umbraco.WidgetTypes.internalLatestCustomersWidget]: dynamic(() => import('./internal-dashboard/latest-customers/latest-customers.component')),
    [Umbraco.WidgetTypes.internalLatestOrdersWidget]: dynamic(() => import('./internal-dashboard/latest-orders/latest-orders.component')),
    [Umbraco.WidgetTypes.internalDashboardManualOrdersLink]: dynamic(
        () => import('./internal-dashboard/show-manual-orders/show-manual-orders.component')
    ),
    [Umbraco.WidgetTypes.videoPopupWidget]: dynamic(() => import('./customer-guide-modal/customer-guide-modal.component')),
    [Umbraco.WidgetTypes.campaignImageSpot]: dynamic(() => import('./campaign-image-spot/campaign-image-spot.component')),
    [Umbraco.WidgetTypes.campaignHeroSlider]: dynamic(() => import('./hero-slider/hero-slider.component')),
};

const renderPageElements = (element: IMappedElement, index: number, columns: number, pageId: string) => {
    const key = `component-${index}-${element?.alias}`;

    switch (element.alias) {
        case Umbraco.PageElementTypes.rte: {
            // alias: "rte"
            const value = element.value as string;
            const RawHtml = moduleList[Umbraco.PageElementTypes.rte];
            return <RawHtml key={key} content={value} />;
        }

        case Umbraco.PageElementTypes.media: {
            // alias: "media"
            const value = element.value as unknown as CmsData.IAliasMedia;
            const Media = moduleList[Umbraco.PageElementTypes.media];
            return <Media key={key} image={{ url: value.udi.src, altText: value.udi.name }} />;
        }

        default: {
            // alias: "docType"
            const widget = element.value as CmsData.IAliasDocType;
            const widgetName = widget.dtgeContentTypeAlias;

            const WidgetComponent = moduleList[widgetName] || moduleList[Umbraco.WidgetTypes.unknownWidget];

            const componentProps = { ...widget?.value, widgetName };

            if (widgetName === 'nestedGrid') {
                return (
                    <ErrorBoundary FallbackComponent={Error} key={key}>
                        <Grid grid={componentProps?.cmsData.grid} pageId={pageId} widgetId={widget.id} />
                    </ErrorBoundary>
                );
            }

            return (
                <ErrorBoundary FallbackComponent={Error} key={key}>
                    <WidgetComponent
                        columns={columns}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        dtgeContentTypeAlias={widget.dtgeContentTypeAlias}
                        widgetId={widget.id}
                        pageId={pageId}
                        {...componentProps}
                    />
                </ErrorBoundary>
            );
        }
    }
};

const PageElements: FC<IPageElementsProps> = ({ pageElements, columns = 12, pageId }) => (
    <>
        {pageElements
            ?.map<IMappedElement>(({ value, editor }) => ({
                value,
                alias: editor.alias,
            }))
            ?.map((elements, i) => renderPageElements(elements, i, columns, pageId))}
    </>
);

const Error = ({ error, resetErrorBoundary }: FallbackProps) => (
    // TODO: Handle errors better
    <section style={{ margin: '30px' }}>
        <ErrorBox>
            <strong>Æv, det ser ud til at der skete en fejl. Fejlen er rapporteret til vores teknikere.</strong>
            <br />

            <pre style={{ margin: '10px 0 15px' }}>{error?.message}</pre>

            <Button aria-label="Prøv igen" onClick={resetErrorBoundary}>
                Prøv igen
            </Button>
        </ErrorBox>
    </section>
);

export default PageElements;
